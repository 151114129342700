.career {
    .subbanner {
        // box-shadow: 5px 10px 8px 10px #888888;
        border-bottom: 1px solid gray;
        margin-bottom: 100px;

        .subbannerside {

            .subbannerh1 {
                // color: var(--color-pri);
                font-size: 40px;
            }

            .subbannerpara {
                // color: var(--color-pri);
                // color: var(--color-sec);
                color: white;
            }
        }
    }
}

@media screen and (max-width: 769px) {
    .career {
        .subbanner {
            margin-bottom: 50px;
        }
    }

}