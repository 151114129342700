.blogcta {
    width: 100%;
    height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .blogctainner {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
            color: var(--color-pri);
            font-size: 36px;
        }

        p {
            width: 40%;
            text-align: center;
            font-size: var(--font-size);

            color: white;
        }

        .blogbtn {
            background: var(--color-pri);
            color: white;
            width: 160px;
            outline: none;
            border: none;
            padding: 10px 0px;
            border-radius: 3px;
            font-size: 17px;
            font-weight: 500;
            margin-top: 10px;
            transition: all .5s ease;

            a {
                text-decoration: none;
                color: white;
            }

            &:hover {
                background-color: var(--color-prisec);

                a {
                    color: var(--color-pri);
                    font-weight: 600;
                }
            }

        }
    }
}

@media only screen and (max-width: 900px) {
    .blogcta .blogctainner p {
        width: 80%;
    }
}