.quickform {
    width: 55%;
    // background: rebeccapurple;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    justify-content: center;
    align-items: center;

    .image {
        display: grid;

        img {
            width: 100%;
            // margin: 0 auto;
            height: 380px;
            border-radius: var(--border-radius);
        }
    }

    .quickforminner {
        h1 {
            color: var(--color-pri);
            // color: white;
            font-size: 36px;
        }

        form {
            .contactinnerright {
                color: var(--color-pri);

                input {
                    width: 100%;
                    padding: 10px 5px;
                    border-radius: 3px;
                    outline: none;
                    box-shadow: none;
                    border: none;
                    border: 1px solid var(--color-pri);
                    margin-bottom: 10px;
                }

                .contactbtn {
                    font-size: 16px;
                    width: 200px;
                    padding: 8px 30px;
                    background: var(--color-pri);
                    color: white;
                    font-weight: bold;
                    border-radius: var(--border-radius);
                    border: none;
                    outline: none;
                    margin-top: 20px;
                }
            }
        }
    }
}