.taxcalculator {
  width: auto;
  height: 400px;
  background: var(--color-pri);
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 50px;
  grid-gap: 2rem;
  margin: 100px 0px;

  h1 {
    color: white !important;
    // margin-bottom: 10px;
    text-align: left !important;
    margin-bottom: 5px;
    line-height: 1em;
  }

  .fst {
    color: white;

    h3 {
      font-weight: 500;
      margin-bottom: 5px;
    }

    p {
      font-size: var(--font-size);
      margin-bottom: 5px;
      text-align: left !important;
    }
  }

  .inputwrapper {
    .insc {
      grid-column: span 2;
      color: white;

      .para1 {
        margin-bottom: 30px;
      }
    }

    .wrap {
      margin-bottom: 30px;

      .da {
        width: 80%;
      }

      p {
        font-size: var(--font-size);
        line-height: 1.3em;
        color: white;
        font-weight: 400;
        margin: 5px 0px;
        width: 80%;
        // margin-bottom: 8px;
      }

      .textfields {
        padding: 10px;
        font-size: 18px;
        border-radius: 5px;
        outline: none;
        border: none;
        width: 80%;
        text-align: right;
      }
    }

    .btn {
      padding: 10px;
      font-weight: 600;
      color: var(--color-pri);
      background: var(--color-prisec);
      font-size: 18px;
      border-radius: 5px;
      outline: none;
      border: none;
      width: 84%;
      margin-top: 10px;

      &:hover {
        background: white;
      }
    }
  }
}
p.para1 a {
  color: white;
  text-decoration: underline;
}
p a {
  color: white;
  text-decoration: underline;
}
@media only screen and (max-width: 915px) {
  .taxcalculator {
    height: auto;
  }
}

@media only screen and (max-width: 715px) {
  .taxcalculator {
    width: auto;
    grid-template-columns: 1fr;
  }

  .taxcalculator .inputwrapper .wrap .da {
    width: 100%;
  }
  .taxcalculator .inputwrapper .wrap p {
    width: 100%;
  }
  .taxtoolkit .texttoolkitsec p {
    width: 100%;
  }

  .taxcalculator {
    padding: 20px;
  }
}
