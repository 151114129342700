.Certification {
  width: 100%;

  .Certificationinner {
    width: 80%;
    margin: 0 auto;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;

    .certificateleft {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 80%;

      .certificateh3 {
        color: var(--color-sec);
        font-size: 24px;
        font-weight: 600;
        // line-height: 1em;
      }

      .certificateh1 {
        color: var(--color-pri);
        font-size: 40px;
        line-height: 1em;
        font-weight: 800;
      }

      .certificatepara {
        font-size: 14px;
        line-height: 1.5em;
        margin-top: 10px;
      }

      .certificatebtn {
        background: var(--color-pri);
        color: var(--color-sec);
        width: 160px;
        outline: none;
        border: none;
        padding: 10px 0px;
        border-radius: 5px;
        font-size: 17px;
        font-weight: 500;
      }
    }
    .certificateright {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;
      width: 400px;
      margin: 0 auto;
      justify-items: flex-end;
      img {
        width: 150px;
        transition: all 0.5s ease;
        // margin-bottom: 10px;

        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}
@media only screen and (max-width: 1083px) {
  .Certification .Certificationinner .certificateleft {
    width: 100%;
  }
  .Certification .Certificationinner .certificateright {
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .Certification .Certificationinner {
    grid-template-columns: 1fr;
  }
  .Certification .Certificationinner .certificateright {
    grid-template-columns: repeat(4, 1fr);
    margin-top: 20px;
    justify-items: flex-start;
  }
}

@media only screen and (max-width: 855px) {
  .Certification .Certificationinner .certificateright {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 517px) {
  .Certification .Certificationinner {
    width: 90%;
  }
}
