.aboutinner {
  width: 100%;

  .aboutinnersec {
    width: 80%;
    margin: 0 auto;

    .aboutinnerdivider {
      display: grid;
      align-items: center;
      grid-template-columns: repeat(2, 1fr);

      .aboutinnerleft {
        display: flex;
        flex-direction: column;
        // justify-self: center;
        justify-content: center;
        width: 90%;

        h1 {
          color: var(--color-pri);
          font-size: 36px;
          line-height: 1.2em;
          margin-bottom: 10px;
        }

        h2 {
          margin-top: 10px;
          color: var(--color-pri);
        }

        p {
          font-size: var(--font-size);
          line-height: 1.5em;
        }

        .para {
          margin-bottom: 10px;
          a {
            color: var(--color-pri);
            font-weight: 600;
          }

          span {
            color: var(--color-pri);
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0.1em;
          }
        }
        .colorpri {
          a {
            margin-bottom: 10px;
            color: var(--color-pri);
            font-weight: 600;
          }
        }
      }

      .aboutinnerright {
        width: 100%;
        display: grid;
        justify-items: flex-end;

        img {
          width: 100%;
          border-radius: 3px;
          // height: 600px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1321px) {
  .aboutinner .aboutinnersec .aboutinnerdivider {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .aboutinner .aboutinnersec {
    width: 90%;
  }
  .aboutinner .aboutinnersec .aboutinnerdivider .aboutinnerleft {
    width: 100%;
  }
}
@media only screen and (max-width: 582px) {
  .mvsec .Businesstaxsecinner .row .cols .sec {
    gap: 0.5rem !important;
    padding: 20px 0px !important;
  }
  .mvsec {
    margin-bottom: 50px !important;
  }
}
