.services {
    .aboutservice {
        margin: 100px 0px;
    }

    .whychoseus {
        margin: 100px 0px;
    }
}

@media only screen and (max-width: 600px) {
    .services .whychoseus {
        margin: 50px 0px;
    }
}