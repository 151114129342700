.taxsolcont {
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 800px 500px;
  justify-content: space-around;
  align-items: center;
  margin-top: 100px;

  .datasec {
    padding: 50px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 30px;
    h2 {
      color: var(--color-pri);
      font-weight: 600;
      font-size: 24px;
      line-height: 1.4em;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 10px;
    }
  }

  .imgwrapper {
    width: 500px;
    height: 500px;
    position: relative;
    .pagewrapper {
      position: absolute;
      z-index: 6666;
      width: 100%;
      height: 100%;
      top: 0px;
      .flexi {
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          background: white;
          width: 80%;
          height: 80%;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.5s ease;
          text-align: center;
          line-height: 1.1em;
          font-size: 14px;
          font-weight: 600;
          color: var(--color-sec);
          &:hover {
            scale: 1.1;
          }
        }
      }
      .taxtone {
        position: absolute;
        top: 172px;
        left: 155px;
        background: white;
        width: 188px;
        height: 188px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 6666;
        cursor: pointer;
        h2 {
          display: grid;
          text-align: center;
          font-size: 26px;
          color: var(--color-sec);
          color: black;
          line-height: 1.3em;
          font-weight: 100;
          span {
            font-weight: 800;
            font-size: 40px;
          }
        }
        .line {
          margin-top: 10px;
          width: 70%;
          height: 4px;
          background: rebeccapurple;
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          .blue {
            background: #07a9b4;
            height: 100%;
            width: 100%;
          }
          .orange {
            background: #f6a400;
            height: 100%;
            width: 100%;
          }
          .md {
            background: #eccd00;
            height: 100%;
            width: 100%;
          }
          .lgre {
            background: #a7d207;
            height: 100%;
            width: 100%;
          }
          .gdre {
            background: #1ea95a;
            height: 100%;
            width: 100%;
          }
        }
      }
      .finance_health {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        // background: rebeccapurple;
        position: absolute;
        top: 15px;
        left: 174px;
        span {
          img {
            width: 40px;
          }
        }
      }
      .red_flag {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        // background: red;
        position: absolute;
        top: 136px;
        left: 340px;
        span {
          img {
            width: 40px;
          }
        }
      }
      .automated_bookkeeping {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        // background: pink;
        position: absolute;
        top: 331px;
        left: 276px;
        span {
          img {
            width: 40px;
          }
        }
      }
      .industry_standerds {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        // background: blue;
        position: absolute;
        top: 332px;
        left: 71px;
        span {
          img {
            width: 40px;
          }
        }
      }
      .built_wealth {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        position: absolute;
        top: 137px;
        left: 7px;
        span {
          img {
            width: 40px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1513px) {
  .taxsolcont {
    grid-template-columns: 1fr 500px;
    gap: 2rem;
  }
}
@media screen and (max-width: 1337px) {
  .taxsolcont .datasec h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 1185px) {
  .taxsolcont {
    grid-template-columns: 1fr;
  }
  .taxsolcont .imgwrapper {
    margin: 0 auto;
  }
}

@media screen and (max-width: 627px) {
  .taxsolcont {
    margin-top: 80px;
  }
  .taxsolcont .datasec {
    padding: 30px;
  }
}

@media screen and (max-width: 530px) {
  .taxsolcont .datasec {
    padding: 30px 20px;
  }
  .taxsolcont .datasec h2 {
    font-size: 18px;
  }
  .taxsolcont .datasec p {
    &:nth-last-child(1) {
      margin: 0px;
    }
  }
  .taxsolcont .imgwrapper .pagewrapper .flexi p {
    font-size: 10px;
  }
  .taxsolcont .imgwrapper {
    width: 300px !important;
    height: auto;
  }
  .taxsolcont .imgwrapper img {
    width: 100%;
  }
  .taxsolcont .imgwrapper .pagewrapper .finance_health {
    top: 10px;
    left: 105px;
    width: 88px;
    height: 88px;
  }
  .taxsolcont .imgwrapper .pagewrapper .built_wealth {
    width: 88px;
    height: 88px;
    font-size: 13px;
    top: 83px;
    left: 5px;
  }

  .taxsolcont .imgwrapper .pagewrapper .industry_standerds {
    width: 88px;
    height: 88px;
    font-size: 13px;
    top: 200px;
    left: 44px;
  }
  .taxsolcont .imgwrapper .pagewrapper .automated_bookkeeping {
    width: 88px;
    height: 88px;
    font-size: 13px;
    top: 200px;
    left: 167px;
  }
  .taxsolcont .imgwrapper .pagewrapper .red_flag {
    width: 88px;
    height: 88px;
    font-size: 13px;
    top: 83px;
    left: 206px;
  }
  .taxsolcont .imgwrapper .pagewrapper .built_wealth span img {
    width: 23px;
  }
  .taxsolcont .imgwrapper .pagewrapper .finance_health span img {
    width: 30px;
  }
  .taxsolcont .imgwrapper .pagewrapper .red_flag span img {
    width: 30px;
  }
  .taxsolcont .imgwrapper .pagewrapper .automated_bookkeeping span img {
    width: 30px;
  }
  .taxsolcont .imgwrapper .pagewrapper .industry_standerds span img {
    width: 30px;
  }
  .taxsolcont .imgwrapper .pagewrapper .flexi .ali {
    font-size: 9px;
  }
  .taxsolcont .imgwrapper .pagewrapper .taxtone {
    position: absolute;
    top: 104px;
    left: 94px;
    background: white;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 6666;
    cursor: pointer;
  }
  .taxsolcont .imgwrapper .pagewrapper .taxtone h2 {
    font-size: 22px;
  }
  .taxsolcont .imgwrapper .pagewrapper .taxtone h2 span {
    font-size: 30px;
  }
}
