.businesstax {
    .subbanner .subbannerside .subbannerh1 {
        font-size: 25px;
        line-height: 1.1em;
    }

    .breadcrumb {
        margin-bottom: 100px;
    }
}



@media only screen and (max-width: 600px) {
    .businesstax {
        .breadcrumb {
            margin-bottom: 50px;
        }

    }

    .Businesstaxsec .Businesstaxsecinner .row {
        margin-top: 50px;
    }
}

@media only screen and (max-width: 385px) {
    .subbanner .wrapper .subbannerside {
        width: 80%;
    }
}