.radial_scroll {
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 60px;
  height: 60px;
  background: var(--color-sec);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    color: white;
    font-size: 20px;
  }
}
