.Save_Tax_Build_Wealth {
  width: 100%;
  //   text-align: center;
  .innerFinancialhealth {
    width: 900px;
    margin: 0 auto;
    // background: #c1bcbc2e;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin: 50px auto;
    padding: 50px;
    border-radius: 30px;
    .h2 {
      color: var(--color-pri);
      font-size: 22px;
    }
    .h3 {
      color: var(--color-pri);
      //   margin-bottom: 30px;
      font-size: 22px;
      padding: 30px 0px;
    }
    .spsec {
      p {
        display: grid;
        padding: 30px 0px;
        border-top: 1px solid var(--color-sec);
        font-size: 18px;
        font-weight: 600;
        color: var(--color-sec);
        &:nth-last-child(1) {
          //   border-bottom: 1px solid var(--color-sec);
          padding-bottom: 0px;
        }
        span {
          color: var(--color-pri);
          font-size: 20px;
          margin-right: 10px;
        }
      }
    }
    .multipara {
      p {
        padding-top: 10px;
        font-size: 18px;
        font-weight: 600;
        color: var(--color-sec);

        &:nth-child(1) {
          padding-top: 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1046px) {
  .Save_Tax_Build_Wealth .innerFinancialhealth {
    width: 90%;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 896px) {
  .Save_Tax_Build_Wealth .innerFinancialhealth .h2 {
    font-size: 20px;
  }
  .Save_Tax_Build_Wealth .innerFinancialhealth .h3 {
    font-size: 20px;
  }
  .Save_Tax_Build_Wealth .innerFinancialhealth .spsec p {
    font-size: 16px;
  }
  .Save_Tax_Build_Wealth .innerFinancialhealth .spsec p span {
    font-size: 18px;
  }
  .Save_Tax_Build_Wealth .innerFinancialhealth .multipara p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 584px) {
  .Save_Tax_Build_Wealth .innerFinancialhealth .h3 {
    padding: 20px 0px;
    margin-bottom: 0px !important;
  }
  .Save_Tax_Build_Wealth .innerFinancialhealth {
    margin: 20px auto;
    padding: 30px 20px !important;
  }
  .Save_Tax_Build_Wealth .innerFinancialhealth .h2 {
    font-size: 18px;
  }
  .Save_Tax_Build_Wealth .innerFinancialhealth .h3 {
    font-size: 18px;
  }
  .Save_Tax_Build_Wealth .innerFinancialhealth .spsec p {
    padding: 20px 0px;
  }
  .Save_Tax_Build_Wealth .innerFinancialhealth {
    padding: 50px 20px;
  }
  .Save_Tax_Build_Wealth .innerFinancialhealth .multipara p {
    padding-top: 20px;
  }
  .Save_Tax_Build_Wealth .innerFinancialhealth .h3 {
    margin-bottom: 20px;
  }
}
