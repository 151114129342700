.financial_health {
  width: 100%;
  height: auto;
  .subbanner {
    background-position: top right;
    background-size: cover;
    .wrapper {
      background: rgba(0, 0, 0, 0);
      .subbannerside {
        .subbannerh1 {
          font-size: 42px;
        }
      }
    }
  }
  .processwrapper {
    width: 100%;
    margin: 0 auto;
    position: relative;
    .Floatingfhealth {
      // top: 200px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .financial_health .subbanner {
    background-position: right !important ;
  }
  .financial_health .subbanner .wrapper .subbannerside {
    width: 90%;
  }
  .subbanner .wrapper {
    background: rgba(1, 1, 1, 0.5) !important;
  }
}
