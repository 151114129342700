.breadcrumb {
    width: 100%;
    height: 60px;
    // background: #eef4ff;
    background: #dfdddd54;

    .breadcrumbinner {
        width: 80%;
        height: 100%;
        margin: 0 auto;
        display: grid;
        align-items: center;

        p {
            font-size: 16px;
            display: flex;
            align-items: center;
            cursor: pointer;

            a {
                text-decoration: none;
                color: var(--color-pri);
                font-weight: 500;
            }

            span {
                margin: 0px 10px;
                color: rgba(42, 69, 93, 0.5);
                font-weight: 600;
                font-size: 20px;
            }
        }
    }
}


@media only screen and (max-width: 600px) {

    .breadcrumb .breadcrumbinner {
        width: 90%;
    }

    .breadcrumb .breadcrumbinner p {
        font-size: 14px;
    }

}

@media only screen and (max-width: 414px) {
    .breadcrumb .breadcrumbinner p {
        font-size: 13px;
    }

    .breadcrumb .breadcrumbinner p span {
        margin: 0px 2px;
    }
}