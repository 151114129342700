.footer {
    width: 100%;
    // height: 300px;
    background: #4343430d;
    padding: 50px 0px;

    .footerinner {
        width: 70%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        .footersec1 {
            padding-left: 60px;
        }

        .footersec2 {
            padding-left: 30px;
        }

        .footersec,
        .footersec2,
        .footersec1,
        .footersec3 {
            width: 100%;
            display: flex;
            flex-direction: column;
            // margin-top: 65px;

            span {
                width: 50px;
                height: 3px;
                background: var(--color-pri);
                margin: 7px 0px;
            }

            img {
                width: 200px;
            }

            p {
                font-size: 12px;
                color: var(--color-sec);

                width: 80%;
                margin: 15px 0px;
            }

            .socialicon {
                a {
                    text-decoration: none;

                    .icon {
                        color: var(--color-pri);
                        font-size: 18px;
                        margin-right: 15px;
                    }
                }
            }

            .footerhe {
                color: black;

            }

            .footerul {
                list-style: none;

                .icon {
                    color: var(--color-pri);
                    margin-right: 10px;
                }

                li {
                    display: flex;
                    font-size: 14px;
                    align-items: center;
                    color: var(--color-sec);
                    margin-top: 10px;


                    a {
                        text-decoration: none;
                        color: var(--color-sec);

                    }
                }
            }

            .ss {
                margin-top: 30px;
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    .footer .footerinner {
        width: 80%;
    }
}


@media only screen and (max-width: 900px) {
    .footer {
        height: auto;
        padding-bottom: 60px;

        .footerinner {
            grid-template-columns: repeat(2, 1fr);

            .footersec1 {
                padding-left: 0px;
            }

            .footersec2 {
                padding-left: 0px;
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    .footer {
        .footerinner {
            gap: 2rem;
            grid-template-columns: repeat(2, 1fr);

            .footersec {
                grid-column: span 2;

            }

            .footersec3 {
                grid-column: span 2;
            }
        }
    }

    .footer .footerinner .footersec p {
        width: 100%;
    }
}
