.personaltax {
    .Businesstaxsec{
        margin-top: 100px;
    }
    .subbanner .subbannerside .subbannerh1 {
        font-size: 30px;
        line-height: 1.1em;
    }

    .personaltaxsec {
        margin-top: 100px;
    }
}