.sticky {
  position: fixed;
  top: 0;
  left: 0;
}
.navcont {
  width: 100%;
  height: 100px;
  z-index: 9999;

  .navupper {
    width: 100%;
    height: 30px;
    background: var(--color-pri);

    .upperinner {
      width: 80%;
      height: 100%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 0fr 2fr;

      .upperleft {
        display: flex;
        align-items: center;

        .icon {
          color: white;
          font-size: 16px;
          display: flex;
          margin-right: 20px;
        }
      }

      .upperright {
        align-items: center;
        display: flex;
        justify-content: flex-end;

        div {
          a {
            text-decoration: none;
            margin-left: 30px;
            color: white;
            display: flex;
            font-size: 13px;
            align-items: center;

            .icon {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .navwraper {
    position: relative;
    height: 70px;
    margin: 0 auto;
    display: grid;
    align-items: center;
    grid-template-columns: 30% 70%;
    width: 80%;
    // after getting issue
    width: 100%;
    padding: 0px 10%;
    box-sizing: border-box;
    background: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    .mobicon {
      font-size: 30px;
    }

    .logo {
      a {
        display: flex;

        img {
          width: 240px;
          // transform: translateX(-50px);
        }
      }
    }

    .show {
      left: 0%;
    }

    .hide {
      left: -100%;
    }

    .navul {
      width: 100%;
      transition: all 1s ease;

      ul {
        display: flex;
        justify-content: right;
        align-items: center;

        a {
          text-decoration: none;
          // margin-left: 30px;
          padding: 0px 15px;
          color: black;
          height: 100%;
        }
        .abo {
          &:hover .subnavabout {
            display: block !important;
          }
          .subnavabout {
            display: none !important;
            cursor: pointer;
            position: absolute;
            display: flex;
            z-index: 9999;
            background: white;
            width: max-content;
            flex-direction: column;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;

            li {
              padding: 10px 30px;
              position: relative;

              &:hover {
                background: var(--color-pri);
              }

              span {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }

              &:hover .showinnersub {
                display: inline-block;
              }

              .showinnersub {
                display: none;
                position: absolute;
                // display: flex;
                background: white;
                width: 238px;
                flex-direction: column;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                right: -238px;
                top: 0;

                li {
                  &:hover {
                    background: var(--color-pri);
                  }
                }
              }

              &:nth-child(5) {
                padding-bottom: 20px;
              }

              a {
                margin: 0px;
              }
            }
          }
        }
        li {
          list-style: none;
          font-size: 15px;
          font-weight: 500;
        }

        .activeservice {
          color: var(--color-pri);
          font-weight: 600;
          // text-decoration: underline;
          // border-bottom: 2px solid #1DAA5B;
        }

        .serve {
          position: relative;

          &:hover {
            .subnav {
              display: block !important;
            }
          }

          .subnav {
            display: none !important;
            position: absolute;
            display: flex;
            z-index: 9999;
            background: white;
            width: 290px;
            flex-direction: column;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

            li {
              padding: 10px 20px;
              position: relative;

              &:hover {
                background: var(--color-pri);
              }

              span {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }

              &:hover .showinnersub {
                display: inline-block;
              }

              .showinnersub {
                display: none;
                position: absolute;
                // display: flex;
                background: white;
                width: 238px;
                flex-direction: column;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                right: -238px;
                top: 0;

                li {
                  &:hover {
                    background: var(--color-pri);
                  }
                }
              }

              &:nth-child(5) {
                padding-bottom: 20px;
              }

              a {
                margin: 0px;
              }
            }
          }
        }

        .navbtn {
          margin-left: 30px;
          padding: 5px 20px;
          list-style: none;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: var(--border-radius);
          border: none;
          background-color: var(--back-pri);
          color: white;
          font-weight: 500;

          .navic {
            font-size: 14px;
            margin-right: 13px;
          }
        }

        .call {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: black;
          line-height: 1.3em;
          font-size: 14px;

          .icon {
            margin-right: 10px;
            font-size: 20px;
            color: var(--color-pri);
            // margin-left: 230px;
          }
        }
      }
    }
  }
}
.navcont .navwraper .navul .subnavshowab {
  display: block;
}
.navcont .navwraper .navul .subnavhideab {
  display: none;
}
@media only screen and (max-width: 1550px) {
  .navcont .navwraper .navul ul .call .icon {
    margin-left: 0px;
  }

  .navcont .navwraper .navul ul {
    justify-content: right;
  }
}

@media only screen and (max-width: 1204px) {
  .navcont .navwraper {
    grid-template-columns: auto 1fr;
    padding: 0px 5%;
  }
  .navcont .navwraper .logo a img {
    transform: initial;
  }
  .navcont .navupper .upperinner {
    width: 90%;
  }

  .navcont .navupper .upperinner .upperright div a {
    margin-left: 10px;
  }

  .navcont .navupper .upperinner .upperright div a .icon {
    margin-right: 5px;
  }
}

@media only screen and (max-width: 1021px) {
  .navcont .navwraper .navul ul a {
    padding: 0px;
  }
  .navcont {
    height: 70px;

    .navupper {
      display: none;
    }

    .navwraper {
      grid-template-columns: 100px 1fr;
      width: 100%;
      justify-items: center;

      .mobicon {
        display: flex;
      }

      .navul {
        position: absolute;
        top: 70px;
        z-index: 9999;
        background: white;

        // -------------------
        ul {
          display: block;
          height: 150vh;
          padding-left: 40px;
          position: relative;

          li {
            margin-top: 20px;
            width: 100px;
            .abouticon {
              display: flex;
              align-items: center;

              .icondown {
                margin-left: 10px;
                transform: rotate(0deg);
                transition: all 0.5s ease;
              }

              .iconup {
                margin-left: 10px;
                transform: rotate(180deg);
                transition: all 0.5s ease;
              }
            }
          }

          a {
            margin-left: 1px;
          }

          .call {
            margin-top: 20px;

            .icon {
              margin-left: 0px;
            }
          }

          .service {
            // display: flex;
            flex-direction: column;
            align-items: center;

            ul {
              padding-left: 40px;
              height: auto;

              li {
                width: auto;
              }
            }

            span {
              display: flex;
              align-items: center;
            }

            .icondown {
              margin-left: 10px;
              transform: rotate(0deg);
              transition: all 0.5s ease;
            }

            .iconup {
              margin-left: 10px;
              transform: rotate(180deg);
              transition: all 0.5s ease;
            }

            .subnavshow {
              display: block;
              height: auto;
              padding-left: 30px;
              width: 300px;

              li {
                .innersubinnershow {
                  height: auto;
                  display: block;
                }

                .innersubinnerhide {
                  display: none;
                }
              }
            }

            .subnavhide {
              display: none;
              height: auto;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .navcont .navwraper {
    padding: 0px;
  }
  .navcont .navwraper .logo a img {
    transform: translateX(-30px);
  }
}

@media only screen and (max-width: 424px) {
  .navcont .navwraper {
    grid-template-columns: 80px 1fr;
  }
  .navcont .navwraper .mobicon {
    font-size: 24px;
  }
}
