.Processheading {
  width: 100%;
  text-align: center;
  h2 {
    width: 100%;
    height: 100px;
    background: var(--color-pri);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    span {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      color: var(--color-pri);
      margin-right: 20px;
    }
  }
}

@media only screen and (max-width: 917px) {
  .Processheading h2 {
    font-size: 24px;
  }
  .Processheading h2 span {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 721px) {
  .Processheading h2 {
    display: grid;
    justify-items: center;
    height: auto;
    padding: 20px;
    box-sizing: border-box;
    gap: 0.5rem;
    line-height: 1.1em;
  }
  .Processheading h2 span {
    margin: 0px;
  }
}
