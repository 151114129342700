.whychoseus {
    width: 100%;
    // height: 500px;

    .whychoseusinner {
        width: 80%;
        height: 100%;
        margin: 0 auto;

        .descsec {
            width: 45%;

            h1 {
                color: var(--color-pri);
                font-weight: bolder;
                font-size: 40px;
                line-height: 1.2em;

            }

            p {
                color: var(--color-sec);
                font-size: 15px;
            }
        }

        .whyinnermultic {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            text-align: center;
            grid-gap: 2rem;
            margin-top: 50px;


            .whycont {
                border: 1px solid var(--color-pri);
                border-radius: 3px;
                outline: none;
                padding: 30px;
                transition: all 1s ease;

                &:hover {
                    transform: scale(1.1);
                }

                img {
                    width: 60px;
                }

                h1 {
                    color: black;
                    font-size: 20px;
                }

                p {
                    font-size: 13px;
                }


            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .whychoseus .whychoseusinner .descsec {
        width: 80%;
    }

    .whychoseus .whychoseusinner .whyinnermultic {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 650px) {
    .whychoseus .whychoseusinner .descsec {
        width: 100%;
    }

    .whychoseus .whychoseusinner .whyinnermultic {
        grid-template-columns: 1fr;
    }
    .whychoseus .whychoseusinner .descsec h1{
        font-size: 30px;
    }
    .whychoseus .whychoseusinner .descsec p{
        font-size: 14px;
    }
}