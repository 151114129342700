.floatingnav {
  position: absolute;
  width: 250px;
  height: auto;
  background: var(--color-pri);
  color: white;
  top: 10px;
  left: 0px;
  // transform: translateY(-50%);
  padding: 60px 20px;
  border-radius: 5px;
  box-shadow: 4px 4px 7px #b0b0b0;
  transition: all 1s ease;

  .servicewrapper {
    position: relative;

    h1 {
      font-size: 26px;
    }

    .line {
      width: 80px;
      height: 3px;
      background: white;
      margin-bottom: 10px;
    }

    .toogle {
      position: absolute;
      display: flex;
      right: -50px;
      top: 130px;
      background: var(--color-prisec);
      width: 30px;
      height: 50px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 4px 4px 7px #b0b0b0;

      p {
        color: white;
        font-size: 30px;
      }
    }

    .toogle1 {
      position: absolute;
      display: flex;
      right: -45px;
      top: 130px;
      background: var(--color-pri);
      width: 30px;
      height: 50px;
      align-items: center;
      justify-content: center;
      box-shadow: 4px 4px 7px #b0b0b0;
      cursor: pointer;

      p {
        color: white;
        font-size: 30px;
      }
    }

    .services {
      a {
        color: white;
        text-decoration: none;

        li {
          display: flex;
          align-items: center;
          justify-content: start;
          padding: 10px 0px;
          list-style: none;
          font-size: 17px;
          line-height: 1.1;
          img {
            width: 60px;
            margin-right: 3px;
          }
          .icon {
            font-size: 40px;
            margin-right: 20px;
          }

          a {
            display: block;
          }
        }
      }
    }
  }
}

.floatingnavhide {
  left: -290px;
}

@media only screen and (max-width: 600px) {
  .floatingnav .servicewrapper .toogle1 {
    right: -40px;
    width: 20px;
  }

  .floatingnav .servicewrapper .toogle {
    right: -40px;
    width: 20px;
  }
}
