.mvsec {
  width: 80%;
  margin: 50px auto;
  position: relative;
  .missionvission {
    margin-bottom: 20px;
    .para {
      width: 600px;
      margin: 0 auto;
      margin-bottom: 10px;
      text-align: center;
      span {
        cursor: pointer;
        font-size: 36px;
        color: var(--color-pri);
        font-weight: 600;
        display: block;
      }
    }
  }
  a {
    color: var(--color-pri);
    border-bottom: 2px solid var(--color-pri);
    text-decoration: none;
    font-weight: 600;
  }

  .Businesstaxsecinner {
    height: 100%;
    margin: 50px auto;
    width: 800px;

    .fp {
      text-align: justify;
      font-size: var(--font-size);
    }

    .fp2 {
      margin-top: 20px;
      font-size: var(--font-size);
      a {
        color: black;
        text-decoration: none;
        border: none;
        font-weight: 500;
      }
    }

    .h1 {
      text-transform: capitalize;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      font-size: 26px;
      color: var(--color-sec);
      font-weight: 600;
    }

    .line {
      width: 180px;
      height: 3px;
      background: var(--color-pri);
      margin: 0 auto;
      margin-top: 4px;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
      font-size: var(--font-size);
    }

    .twoseccol {
      display: grid;
      grid-template-columns: 1fr 300px;
      position: relative;
      align-items: center;

      p {
        br {
          margin-bottom: 20px;
        }
      }

      img {
        width: 250px;
        margin: 0 auto;
        position: absolute;
        right: 0px;
        bottom: 0px;
      }
    }

    .row {
      width: 100%;
      display: grid;

      .cols:last-child {
        border-bottom: 1px solid var(--color-sec);
      }

      .cols {
        .sec {
          display: grid;
          grid-template-columns: 300px 1fr;
          padding: 30px 0px;
          border-top: 1px solid var(--color-sec);
          align-items: center;

          .fb {
            font-weight: 600;
            color: var(--color-pri);
            font-size: 18px;
            width: 240px;
            display: flex;
            align-items: center;
            .icon {
              margin-right: 10px;
              font-size: 20px;
            }
            p {
              margin: 0px;
              line-height: 1.3em;
              font-size: 18px;
              font-weight: 600;
            }
            img {
              width: 40px;
              margin-right: 10px;
            }
          }
        }
      }
    }
    .dflex {
      display: inline-block;
      align-items: center;
      margin-top: 20px;
      p {
        display: inline;
        a {
          border: none;
          color: black;
          font-weight: 100;
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .mvsec .missionvission .para {
    width: 100%;
  }
  .mvsec .Businesstaxsecinner {
    width: 100%;
  }
}
@media only screen and (max-width: 772px) {
  .mvsec .Businesstaxsecinner .row .cols .sec {
    grid-template-columns: 230px 1fr;
  }
  .mvsec {
    width: 90%;
  }
}

@media only screen and (max-width: 510px) {
  .mvsec .Businesstaxsecinner .row .cols .sec {
    grid-template-columns: 1fr;
  }
  .mvsec .Businesstaxsecinner .row .cols .sec .fb {
    width: auto;
    margin: 0 auto;
  }
  .paragragh {
    text-align: center;
  }
}
