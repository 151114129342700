.consultingservices {
    .subbanner .subbannerside .subbannerh1 {
        font-size: 30px;
        line-height: 1.1em;
    }

    .personaltaxsec {
        margin-top: 100px;
    }
}

@media only screen and (max-width: 600px) {
    .consultingservices {
        .personaltaxsec {
            margin-top: 50px;
        }
    }
}