.testimonialsec {
  width: 100%;
  height: auto;

  .testisec {
    width: 80%;
    display: grid;
    justify-content: space-between;
    margin: 0 auto;
    grid-template-columns: 50% 50%;
    padding: 0px 50px;
    grid-gap: 2rem;
    align-items: flex-start;

    .testleftsec {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      line-height: 1;

      h3 {
        // color: var(--color-sec);
        margin-bottom: 10px;
        font-size: 28px;
      }

      h1 {
        font-size: 36px;
        font-weight: 600;
        color: var(--color-pri);
        line-height: 0.9em;
        margin-bottom: 10px;
        font-family: "Poppins";
      }

      p {
        font-size: var(--font-size);

        line-height: 1.5em;
        // color: var(--color-sec);
      }
    }

    .testrightsec {
      height: 100%;
      justify-content: center;
      padding: 130px 0px;

      .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 60px;
        border-radius: 3px;
        min-height: 200px;
        max-height: auto;
        background: #1daa5b;
        .name {
          font-size: 22px;
          font-weight: 600;
        }

        .lquote {
          font-size: 16px;
          margin-right: 5px;
          bottom: 0 !important;
        }

        .quote {
          font-size: 16px;
          margin-left: 5px;
          bottom: 0 !important;
        }

        p {
          color: white;
          font-size: var(--font-size);
          margin: 0px;
          .marg {
            margin-left: 5px;
          }
        }
      }
    }
  }
}

// .carousel .thumbs {
//     display: flex;
//     justify-content: center;
// }

.carousel .slider-wrapper {
  width: 90% !important;
}

.carousel-status {
  display: none;
}

// .carousel .slide {
//     padding-bottom: 95px;
//     padding-right: 95px;
// }

// .carousel .slider-wrapper.axis-horizontal .slider .slide {

//     margin-right: 0.1px;

// }

.carousel .control-dots .dot {
  display: none !important;
  // background: var(--color-sec) !important;
}
// .carousel .control-dots .dot.selected {
// background: var(--color-pri) !important;
// }

.carousel.carousel-slider .control-arrow {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .testimonialsec .testisec {
    width: 90% !important;
  }
}

@media only screen and (max-width: 1000px) {
  .testimonialsec .testisec {
    display: block;
    // padding-top: 60px;
  }

  .testimonialsec .testisec .testrightsec {
    padding-top: 60px;
    margin-top: 20px;
  }

  .testimonialsec .testisec .testleftsec h1 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .testimonialsec .testisec {
    display: block;
    width: 100%;
    padding: 0px 20px;
    height: auto;
  }

  ul.control-dots {
    display: none;
  }

  .testimonialsec .testisec .testleftsec {
    padding-top: 60px;
  }

  .testimonialsec .testisec .testleftsec h1 {
    font-size: 30px;
  }

  .testimonialsec .testisec .testrightsec {
    padding: 40px 0px;
  }

  .testimonialsec .testisec .testrightsec .box {
    padding: 10px;
    height: 130px;
  }
  .carousel .slider-wrapper {
    width: 100% !important;
  }

  .testimonialsec .testisec .testrightsec .box .boximg {
    display: none;
  }

  .carousel .slide {
    padding-bottom: 0px;
    padding-right: 0px;
  }

  .About .CtaDevider__inner div {
    padding: 4px;
  }
}

@media only screen and (max-width: 454px) {
  .testimonialsec .testisec .testrightsec .box {
    height: 170px;
  }
}
