.taxtoolkit {
  width: 100%;

  .para {
    width: 50%;
    margin: 0 auto;
    text-align: center;
  }

  h1 {
    text-align: center;
  }

  .subbanner .subbannerside .subbannerh1 {
    color: var(--color-pri);
  }

  .subbanner .subbannerside .subbannerpara {
    color: var(--color-sec);
  }

  .texttoolkitsec {
    width: 70%;
    margin: 0 auto;
    margin-top: 100px;

    h1 {
      font-size: 36px;
      color: var(--color-pri);
      font-weight: 500;
    }

    p {
      font-size: var(--font-size);
    }

    .image {
      width: 90%;
      margin: 0 auto;
      display: flex;
      // margin-top: 30px;
    }

    .twosec {
      margin-top: 50px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;
    }

    .toolmapdata {
      display: grid;
      border-radius: 5px;
      align-items: center;
      padding: 20px;
      box-shadow: 0 0 7px 0px grey;
      transition: all 0.5s ease;

      &:hover {
        transform: scale(1.05);
      }

      span {
        font-size: 16px;
      }

      h2 {
        color: var(--color-pri);
        margin: 0px;
        font-size: 26px;
        line-height: 1;
        font-weight: 500;
      }

      p {
        font-size: var(--font-size);
        margin: 10px 0px;
        font-size: 14px;
      }

      a {
        padding: 5px 10px;
        background: var(--color-pri);
        text-decoration: none;
        color: white;
        border-radius: 5px;
        font-size: 16px;
        width: 100px;
        text-align: center;
      }
    }
  }
}

.popupform {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  background: #aeabb159;
  display: flex;
  justify-content: center;
  align-items: center;
  .formbody {
    width: 330px;
    height: auto;
    background: white;
    border-radius: 15px;
    padding: 50px 30px;
    position: relative;
    .close {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 22px;
      color: var(--color-sec);
    }
    .popuphedi {
      h3 {
        color: var(--color-pri);
        font-size: 18px;
      }
      .line {
        width: 180px;
        margin: 8px 0px 10px 0px;
        height: 2px;
        background: var(--color-pri);
      }
    }
    form {
      margin-top: 15px;
      .formfield {
        margin-bottom: 15px;
        input {
          box-sizing: border-box;
          width: 100%;
          padding: 6px 5px;
          border-radius: 3px;
          outline: none;
          box-shadow: none;
          border: none;
          border: 1px solid var(--color-pri);
          margin-top: 5px;
        }
      }
      button {
        font-size: 16px;
        padding: 8px 30px;
        background: var(--color-pri);
        color: white;
        font-weight: bold;
        border-radius: 3px;
        border: none;
        outline: none;
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}
@media only screen and (max-width: 915px) {
  .taxtoolkit .texttoolkitsec {
    width: 90%;
  }
}

@media only screen and (max-width: 715px) {
  .taxtoolkit .texttoolkitsec {
    margin-top: 50px;
  }

  .taxtoolkit .texttoolkitsec .twosec {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 715px) {
  .taxcalculator .inputwrapper .wrap .textfields {
    box-sizing: border-box;
    width: 100%;
  }

  .taxcalculator .inputwrapper .btn {
    width: 100%;
  }

  .taxtoolkit .texttoolkitsec h1 {
    text-align: left;
  }

  .taxtoolkit .texttoolkitsec p {
    text-align: left;
  }
}
