.faq {
    padding: 0px 10%;

    .faqinner {

        .faqhedi {

            h1 {
                color: var(--color-pri);
                font-size: 40px;
            }
        }

        .faqa {
            height: auto;
            margin-top: 10px;

            .faqinner {
                // background: pink;
                display: flex;
                align-items: center;

                .accordion {
                    margin: 0px;
                    width: 100%;

                    .innermao {
                        height: auto;
                        padding: 10px 0px;
                        border-top: 2px solid var(--color-prisec);
                        padding-left: 30px;

                        &:nth-last-child(1) {
                            border-bottom: 2px solid var(--color-prisec);
                        }

                        .wraper {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            cursor: pointer;
                            color: var(--color-pri);


                            .acctitle {
                                margin: 0px;
                                padding: 0px;

                                h4 {
                                    font-size: 18px;
                                    margin: 0px;
                                    font-weight: 400;
                                }

                            }

                            .spanr {
                                font-size: 20px;
                                font-weight: bolder;
                            }

                        }

                        .accpara {
                            margin: 0px;
                            padding: 0px 40px;
                            text-align: justify;
                            line-height: 1.2;
                            margin-top: 5px;
                            color: var(--color-sec);
                        }

                        .hide {
                            display: none;
                        }

                        .active {
                            background: #e7f1ff;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 600px) {
    .faq .faqinner .faqhedi h1{
        font-size: 26px;
    }
    .faq .faqinner .faqa .faqinner .accordion .innermao{
        padding-left: 0px;
    }
    .faq .faqinner .faqa .faqinner .accordion .innermao .accpara{
        padding: 0px 20px;
    }
    .faq .faqinner .faqa .faqinner .accordion .innermao .wraper .acctitle h4{
        font-size: 16px;
    }
    .faq .faqinner .faqa .faqinner .accordion .innermao .accpara p{
        font-size: 13px;
        text-align: left;
    }
    .faq .faqinner .faqa {
    margin-top: 10px;
    }
}