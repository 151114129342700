.subbanner {
  position: relative;
  width: 100%;
  height: 450px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  .wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    color: white;
    background: rgba(1, 1, 1, 0.5);

    .subbannerside {
      padding-left: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 60%;

      .subbannerh1 {
        color: white;
        font-size: 36px;
        line-height: 1em;
        font-weight: 800;
      }

      .subbannerpara {
        font-size: var(--font-size);
        line-height: 1.2em;
        margin: 10px 0px;
      }

      .subbannerbtn {
        background: var(--color-pri);
        color: white;
        width: 160px;
        outline: none;
        border: none;
        padding: 10px 0px;
        border-radius: 2px;
        font-size: 17px;
        font-weight: 500;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .subbanner {
    .wrapper {
      grid-template-columns: 1fr;

      .subbannerside {
        padding-left: 10%;
        width: 70%;
      }
    }
  }

  .businesstax .subbanner .subbannerside .subbannerh1 {
    font-size: 28px !important;
  }
}

@media only screen and (max-width: 600px) {
  .subbanner {
    background-size: cover;
    height: 300px;

    .wrapper {
    }
  }

  .businesstax .subbanner .subbannerside .subbannerh1 {
    font-size: 22px !important;
  }
}
@media only screen and (max-width: 450px) {
  .about .subbanner .subbannerside .subbannerh1 {
    font-size: 26px !important;
    line-height: 1.2em;
  }
}
