.aboutservice {
    width: 100%;
    height: 400px;

    .aboutserviceinner {
        // background: pink;
        width: 80%;
        margin: 0 auto;
        display: grid;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;

        .aboutleftside {
            width: 80%;

            .abouth3 {
                color: var(--color-sec);
                font-size: 30px;
                font-weight: 500;
            }

            .aboutbannerh1 {
                color: var(--color-pri);
                font-size: 45px;
                line-height: 1em;
                font-weight: 800;
            }

            .aboutbannerpara {
                font-size: 14px;
                line-height: 1.2em;
                color: var(--color-sec);
                margin: 10px 0px;
            }

            .aboutbtn {
                background: var(--color-pri);
                cursor: pointer;
                color: white;
                width: 160px;
                outline: none;
                border: none;
                padding: 10px 0px;
                border-radius: 5px;
                font-size: 17px;
                font-weight: 500;
                transition: all .5s ease;

                &:hover {
                    background: var(--color-prisec);
                    color: var(--color-pri);
                    font-weight: 600;
                }

            }
        }

        .aboutrightside {
            display: grid;
            justify-content: right;
            position: relative;

            img {
                width: 80%;
                margin: 0 auto;
                border-radius: 15px;
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .aboutservice .aboutserviceinner .aboutrightside img {
        width: 100%;
    }

    .services {
        .aboutservice {
            height: 300px !important;
        }
    }
}