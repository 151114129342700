.personaltaxsec {
    width: 100%;
    position: relative;
    transition: all .5s ease;


    .personaltaxsecinner {
        width: 50%;
        height: 100%;
        margin: 0 auto;

        .fp {
            text-align: justify;
            margin-top: 20px;


        }

        .fp2 {
            text-align: justify;
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .fp3 {
            text-align: justify;
            margin-top: 30px;
        }

        // background: rebeccapurple;
        .h1 {
            font-size: 30px;
            margin-top: 30px;
            text-transform: capitalize;
        }

        .line {
            width: 80px;
            height: 3px;
            background: var(--color-pri);
            margin-bottom: 20px;

        }

        .mapdata {
            margin-top: 20px;

            .list {
                display: grid;
                width: 100%;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 2rem;

                .innermap {
                    display: grid;
                    grid-template-columns: 50px 1fr;
                    grid-gap: 1rem;

                    p {
                        font-size: 17px;
                        line-height: 1.4em;
                    }

                    .val {
                        display: grid;
                        align-items: center;
                        width: 100%;
                        font-size: 30px;
                        background: var(--color-pri);
                        height: 50px;
                        border-radius: 5px;
                        margin-right: 20px;

                        span {
                            display: grid;
                            width: 85%;
                            background: var(--color-prisec);
                            height: 100%;
                            justify-content: center;
                            align-items: center;
                            margin: 0 0 0 auto;
                            border-bottom-right-radius: 5px;
                            border-top-right-radius: 5px;
                        }

                    }
                }

            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .personaltaxsec .personaltaxsecinner {
        width: 90%;
    }

    .personaltaxsec .personaltaxsecinner .mapdata .list {
        grid-template-columns: 1fr;
    }

    .personaltaxsec .personaltaxsecinner .fp,
    .personaltaxsec .personaltaxsecinner .fp2,
    .personaltaxsec .personaltaxsecinner .fp3 {
        font-size: 15px;
    }

    .personaltaxsec .personaltaxsecinner .mapdata .list .innermap p {
        font-size: 15px;
    }
}