.homeaboutsec {
  width: 100%;
  height: auto;

  .homeaboutinner {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;

    .homeaboutleft {
      display: grid;
      border-radius: var(--border-radius);

      .homeaboutlefte {
        width: 100%;
        border-radius: 2px;
        border-radius: var(--border-radius);
        height: auto;
      }
    }
    .innerupper {
      padding-right: 50px;
    }
    .homeaboutright {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;

      h4 {
        color: #1daa5b;
        font-size: 22px;
        font-weight: 600;
      }

      h1 {
        color: black;
        font-size: 36px;
        line-height: 1em;
        font-weight: 800;
        margin: 5px 0px;
      }

      p {
        font-size: var(--font-size);

        &.fp {
          margin-bottom: 10px;
        }
      }

      ul {
        margin-left: 20px;

        .li {
          margin-top: 10px;

          ul {
            margin-top: 5px;
          }
        }
      }

      .btnsec {
        display: grid;
        grid-template-columns: repeat(2, 180px);
        margin-top: 20px;
        grid-gap: 2rem;

        .homebtns {
          display: flex;
          align-items: center;
          background: var(--color-pri);
          color: white;
          border-radius: 3px;
          padding: 10px 20px;
          cursor: pointer;

          .val {
            font-size: 26px;
            font-weight: 600;
          }

          .small {
            width: 90px;
            margin-left: 10px;
            font-size: 14px;
            line-height: 1.2em;
          }
        }

        .homebtnsinac {
          display: flex;
          align-items: center;
          background: var(--color-prisec);
          color: var(--color-pri);
          border-radius: 3px;
          padding: 10px 20px;
          cursor: pointer;

          .val {
            font-size: 26px;
            font-weight: 600;
          }

          .small {
            width: 90px;
            margin-left: 10px;
            font-size: 14px;
            line-height: 1.2em;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1574px) {
  .homeaboutsec .homeaboutinner .homeaboutleft .homeaboutlefte {
    width: 100%;
    height: auto;
  }
  .homeaboutsec .homeaboutinner {
    grid-template-columns: 600px 1fr;
  }
  .homeaboutsec .homeaboutinner .homeaboutright {
    height: 400px;
    overflow-y: scroll;
    display: grid;
  }
}
@media only screen and (max-width: 1333px) {
  .homeaboutsec .homeaboutinner {
    width: 90%;
  }
  .homeaboutsec .homeaboutinner {
    grid-template-columns: 500px 1fr;
  }

  .homeaboutsec .homeaboutinner .innerupper {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 1078px) {
  .homeaboutsec .homeaboutinner {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 623px) {
  .homeaboutsec .homeaboutinner .homeaboutright{
    height: auto;
  }
}
@media only screen and (max-width: 538px) {
  .homeaboutsec .homeaboutinner .homeaboutleft {
    display: none;
  }

  .homeaboutsec .homeaboutinner {
    width: 100%;
    display: block;
  }

  .homeaboutsec .homeaboutinner .homeaboutright {
    padding: 0px 20px;
    width: auto;
  }

  .homeaboutsec .homeaboutinner .homeaboutright .btnsec {
    grid-template-columns: repeat(2, 163px);
    margin-top: 20px;
    grid-gap: 0.5rem;
  }
}
