.popupAlertContainer {
    position: fixed;
    top: 0px;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    background: #0000008f;
    display: flex;
    justify-content: center;
    align-items: center;

    .textContainer {
        width: 500px;
        padding: 50px;
        background: white;
        border-radius: 5px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        position: relative;

        img {
            width: 250px;
            margin: 0 auto;
            display: flex;
            margin-bottom: 20px;
        }

        .title {
            font-size: 30px;
            color: #1daa5b;
            border-bottom: 2px solid #1daa5b;
            width: max-content;
            margin: 0 auto;
            text-align: center;
            line-height: 1;
            margin-top: 20px;
        }

        p {
            font-size: 17px;
            margin-bottom: 10px;

            &:nth-last-child(1) {
                margin-bottom: 0px;
            }
        }

        .closeBtn {
            width: 30px;
            height: 30px;
            background: rebeccapurple;
            border-radius: 50%;
            background: #1daa5b;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 20px;
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }
    }
}


@media only screen and (max-width: 600px) {
    .popupAlertContainer {
        .textContainer {
            width: 100%;
            padding: 50px 30px;

            img {
                margin: 0;
                margin-bottom: 20px;
            }

            .title {
                margin: 0;
            }

        }
    }

}