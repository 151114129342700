* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins';
}

        a {
          text-decoration: none;
          color: black;
        }
:root {
  width: 100%;
  --color-pri: #1DAA5B;
  --color-sec: #7D7D7D;
  --color-prisec: #DFDDDD;
  --border-radius: 5px;
  --font-size: 16px
}

h1,
h2,
h3 {
  font-size: 20px;
  font-family: 'Poppins';
}

p {
  font-size: 16px;
}

body,
html {
  overflow-x: hidden;
  width: 100%;

}

.App {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

/* ------------------------------------- */
/* delcode pracredux toolkit */

.App {
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100vw;
  height: 100vh;

}

.app .footer {
  margin-top: 100px;
}

@media only screen and (max-width: 600px) {
  .app .footer {
    margin-top: 50px;
  }
}