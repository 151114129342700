.Singleblog {
    width: 100%;

    .heading {
        text-align: center;
        margin: 50px 0px;

        .line {
            border: 2px solid var(--color-pri);
            width: 200px;
            margin: 0 auto;
        }

        h1 {
            color: var(--color-pri);
            font-weight: 800;
            font-size: 50px;
        }
    }

    .singlebloglistinner {
        width: 80%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-gap: 3rem;
        // margin-top: 100px;


        .leftcol {

            h1 {
                color: var(--color-pri);
                font-size: 30px;
            }

            h2,
            h5,
            h3,
            h4 {
                color: var(--color-pri);
                font-size: 30px;
                margin: 25px 0px 5px 0px;
            }

            ul {
                margin-left: 20px;

                li {
                    color: var(--color-pri);
                    font-size: 20px;
                    margin: 20px 0px;
                }
            }

        }

        .rightcol {

            .firstcol {
                margin-bottom: 30px;

                .mapdata {
                    display: flex;
                    box-shadow: 0px 0px 14px 3px #8080805e;
                    margin-bottom: 30px;
                    display: flex;
                    align-items: center;
                    border-radius: 3px;
                    transition: all .5s ease;

                    &:hover {
                        transform: scale(1.1);
                    }

                    img {
                        width: 150px;
                        height: 100px;
                        // border-radius: 10px;
                        border-top-left-radius: 3px;
                        border-bottom-left-radius: 3px;
                        margin-right: 10px;
                        // border-right: 2px solid var(--color-pri);
                        // box-shadow: 5px 0px var(--color-pri);
                    }

                    p {
                        padding: 0px 10px;
                    }
                }

                h1 {
                    font-size: 30px;
                    color: var(--color-pri);
                    margin-bottom: 20px;
                }
            }

            .thirdcol {
                width: 100%;
                height: 300px;
                background-position: center;
                background-size: cover;
                border-radius: 3px;
                margin-top: 50px;

                .thirdinner {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    border-radius: 3px;
                    background-color: rgba(0, 0, 0, 0.7);
                    justify-content: center;
                    padding: 0px 30px;
                    color: white;

                    h1 {
                        color: var(--color-pri);
                        font-size: 30px;
                        font-weight: 600;
                    }

                    .flexi {
                        display: flex;
                        align-items: center;
                        margin-top: 10px;

                        a {
                            text-decoration: none;
                            color: white;
                            margin-left: 10px;
                        }

                        .icons {
                            color: var(--color-pri);
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    .Singleblog .heading h1 {
        font-weight: 600;
        font-size: 30px;
    }

    .Singleblog .heading .line {
        width: 150px;
    }

    .Singleblog .singlebloglistinner {
        grid-template-columns: 1fr;
        width: 100%;

        // padding: 0px 20px;
        .leftcol {
            padding: 0px 20px;
        }

        .rightcol {
            padding: 0px 20px;

            .firstcol {
                margin-bottom: 0px;
            }
        }
    }

    .Singleblog .singlebloglistinner .rightcol .thirdcol {
        margin: 0px;
    }
}

@media only screen and (max-width: 1100px) {
    .Singleblog .heading {
        display: none;
    }

    .Singleblog .singlebloglistinner .leftcol {
        padding: 30px 20px;
    }
}