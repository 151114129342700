.home {
  .homeaboutsec {
    margin: 150px 0px;
  }

  .testimonialsec {
    margin: 150px 0px;
  }

  .testimonialsec .testisec .testrightsec {
    padding: 0px;
  }
}
@media only screen and (max-width: 1333px) {
  .home .homeaboutsec {
    margin: 50px 0px;
  }
}
@media only screen and (max-width: 1000px) {
  .home .testimonialsec {
    margin: 80px 0px;
  }
}
@media only screen and (max-width: 600px) {
  .home {
    .testisec {
      margin-bottom: 39px;
    }
  }
}

@media only screen and (max-width: 530px) {
  .home .homeaboutsec {
    margin: 80px 0px;
  }

  .home {
    .testimonialsec {
      margin: 80px 0px;
    }

    .testimonialsec .testisec .testleftsec {
      padding-top: 0px;
      margin-bottom: 50px;
    }
  }
}
