.cta {
  width: 100%;
  height: 300px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      color: white;
      font-size: 36px;
      line-height: 1em;

      span {
        color: var(--color-pri);
      }
    }

    p {
      color: white;
      margin: 8px 0px;
      width: 40%;
      text-align: center;
      font-size: var(--font-size);
    }

    .aboutbtn {
      background: var(--color-pri);
      color: white;
      width: 160px;
      outline: none;
      border: none;
      padding: 10px 0px;
      border-radius: 2px;
      font-size: 17px;
      font-weight: 500;
      transition: all 0.5s ease;

      a {
        text-decoration: none;
        color: white;
        cursor: pointer;
      }

      &:hover {
        background: var(--color-prisec);
        a {
          color: var(--color-pri);
          font-weight: 600;
        }
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .cta {
    height: 200px;
  }

  .cta .overlay p {
    width: 90%;
  }

  .cta .overlay h1 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 567px) {
  .cta {
    height: auto;
    height: 300px;
  }
}
