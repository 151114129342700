.videosec {
  width: 100%;
  height: 400px;
  background-image: url("../../assets/home/videosec.png");
  // opacity: .9;
  position: relative;
  background-size: 100% auto;

  .floatingvideo {
    position: absolute;
    left: 50%;
    height: 100%;
    width: 100%;
    background: "red";
    display: flex;
    justify-content: center;
    transform: translate(-50%);
    background: rgba(1, 1, 1, 0.5);
    z-index: 1;

    .innervid {
      padding-top: 20px;
    }
  }

  .hide {
    display: none;
  }

  .videosecinner {
    width: 80%;
    margin: 0 auto;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
    position: relative;

    .videohedaing {
      font-size: 36px;
      font-weight: 600;
      color: white;
      line-height: 1.2;

      span {
        color: var(--color-pri);
      }
    }

    .videodesc {
      color: white;
      font-size: var(--font-size);
      font-weight: 400;
    }

    .videoright {
      cursor: pointer;

      img {
        width: 130px;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .videosec {
    .videosecinner {
      grid-template-columns: 1fr 0.3fr;

      .videoright {
        img {
          width: 80px;
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .videosec {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .videosec .videosecinner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    background: #00000061;
    width: 100%;
    padding: 50px 5%;
    box-sizing: border-box;
  }
}
@media only screen and (max-width: 450px) {
  .videosec {
    height: auto;
  }
  .videosec .videosecinner .videohedaing {
    font-size: 32px;
  }
}
