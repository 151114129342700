.MuiBox-root.css-1l9ohcm {
    padding: 49px;
    border: none;
    outline: none;
    border-radius: 15px;

    ul {
        margin: 10px 0px;

        li {
            margin-left: 40px;
        }
    }
}

p#transition-modal-description {
    margin: inherit;
}

.careerfrom {
    width: 70%;
    margin: 0 auto;
    text-align: center;

    h1 {
        margin-top: 20px;
        font-size: 40px;
        color: var(--color-pri);
        margin-bottom: 30px;
        font-weight: 500;
    }

    .jobdesc {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .innerdiv1 {
            display: grid;
            justify-items: center;

            .careericon {
                // filter: hue-rotate(45deg);
                filter: grayscale(1);
                width: 120px;

                &:hover {
                    filter: grayscale(0);
                }
            }

            .careenheading {
                // color: var(--color-pri);
                font-weight: 500;
                font-size: 17px;
                color: var(--color-sec);
            }

            Button {
                cursor: pointer;
                background: var(--color-pri);
                color: white;
                width: auto;
                outline: none;
                border: none;
                padding: 5px 10px;
                border-radius: 5px;
                font-size: 17px;
                font-weight: 500;
                margin-top: 5px;
            }

        }
    }

    .appliedfor {
        font-size: 34px;
        color: var(--color-pri);
        margin-top: 60px;
        margin-bottom: 20px;
    }

    .formcont {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;

        .imgsec {
            img {
                width: 550px;
                height: 550px;
                border-radius: var(--border-radius);
            }
        }

        .contactusformcont {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .contactforminner {
                display: grid;

                .contactinnerright {
                    height: 100%;
                    // background: palevioletred;
                    // background: var(--color-prisec);


                    .ali {
                        display: grid;
                        height: 100%;
                        align-items: center;
                        grid-gap: 1rem;
                        width: 600px;

                        .formfields {
                            display: grid;
                            grid-gap: 1rem;
                            color: var(--color-sec);

                            .formfield {
                                margin-bottom: 0;
                                display: grid;


                                p {
                                    font-size: 1.1em;
                                    margin-bottom: 5px;
                                    color: var(--color-pri);
                                    font-weight: 500;
                                    text-align: left;

                                }

                                input {
                                    width: 80%;
                                    padding: 10px 5px;
                                    border-radius: 3px;
                                    outline: none;
                                    box-shadow: none;
                                    border: none;
                                    border: 1px solid var(--color-pri);
                                }
                            }
                        }

                        .textareasec {
                            display: flex;
                            width: 100%;
                            display: grid;
                            grid-template-columns: 1fr;
                            color: var(--color-pri);

                            .textareac {
                                display: flex;
                                align-items: center;
                            }


                            p {
                                font-size: 1.1em;
                                margin-bottom: 5px;
                                color: var(--color-blue);
                                margin: 0px;
                                margin-right: 20px;

                            }

                            textarea {
                                width: 100%;
                                height: 100px;
                                display: flex;
                                border-radius: var(--border-radius);
                                padding: 5px;
                                color: var(--color-blue);

                            }
                        }
                    }

                    .contactbtn {
                        font-size: 16px;
                        width: 200px;
                        padding: 8px 30px;
                        background: var(--color-pri);
                        color: white;
                        font-weight: bold;
                        border-radius: var(--border-radius);
                        border: none;
                        outline: none;
                        margin-top: 20px;
                    }

                }
            }


        }
    }

}

@media screen and (max-width: 769px) {

    .careerfrom .appliedfor {
        margin-top: 30px;
    }

    .careerfrom .formcont {
        grid-template-columns: 1fr;

        .imgsec {
            display: none;
        }

        .contactusformcont .contactforminner .contactinnerright .ali .formfields .formfield input {
            width: 100%;
        }
    }

    .careerfrom .jobdesc .innerdiv1 .careenheading {
        margin-top: 10px;
        color: var(--color-pri);
        font-weight: 500;
        line-height: 1.1em;
    }

    .careerfrom .formcont .contactusformcont .contactforminner .contactinnerright .ali {
        width: auto;
    }

    .careerfrom .formcont .contactusformcont {
        display: grid;
        justify-content: initial;
    }
}

@media screen and (max-width: 569px) {
    .careerfrom {
        .jobdesc {
            overflow-y: scroll;
            grid-gap: 2rem;
        }

        h1 {
            font-size: 26px;
        }

        .appliedfor {
            font-size: 26px;
            font-weight: 400;
        }

        .jobdesc .innerdiv1 .careericon {
            width: 80px;
        }

        .jobdesc .innerdiv1 .careenheading {
            font-size: 15px;
        }
    }

    .MuiBox-root .css-1l9ohcm {
        border-radius: 15px !important;
    }
}