.Centeredcontent {
    width: 70%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 50px;

    h1 {
        color: var(--color-pri);
        font-size: 36px;
        font-weight: 500;
    }

    p {
        font-size: 14px;
        width: 60%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 769px) {

    .Centeredcontent {
        width: 90%;

        margin-bottom: 30px;

    }

    .Centeredcontent p {
        width: 90%;
    }
}

@media screen and (max-width: 569px) {
    .Centeredcontent {
        width: 100%;

        h1 {
            font-size: 26px
        }

        p {
            font-size: 13px;
        }
    }
}