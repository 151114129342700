.Bloglist {
    width: 100%;

    .heading {
        text-align: center;
        margin: 50px 0px;

        .line {
            border: 2px solid var(--color-pri);
            width: 200px;
            margin: 0 auto;
        }

        h1 {
            color: var(--color-pri);
            font-weight: 800;
            font-size: 50px;
        }
    }

    .Bloglistinner {
        width: 80%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-gap: 3rem;
        // margin-top: 100px;


        .leftcol {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 5rem;

            .blogboxarea {
                position: relative;
                width: 100%;
                padding-bottom: 10px;
                border-bottom: 2px solid var(--color-prisec);
                transition: all 1s ease;
                cursor: pointer;

                &:hover {
                    transform: scale(1.05);
                }

                .cat {
                    min-width: 40px;
                    font-size: 13px;
                    text-align: center;
                    background: var(--color-prisec);
                    border-radius: 3px;
                    margin: 5px 0px;
                    color: var(--color-pri);
                    text-transform: capitalize;
                    padding: 0px 5px;
                    width: max-content;

                }

                p {
                    font-size: 15px;
                    line-height: 2em;
                    color: var(--color-sec);
                }

                h1 {
                    font-size: 24px;
                    color: var(--color-pri);
                    font-weight: 700;
                    line-height: 1.3em;
                }

                .btn {
                    // position: absolute;
                    background: var(--color-pri);
                    color: white;
                    width: 160px;
                    outline: none;
                    border: none;
                    padding: 10px 0px;
                    border-radius: 5px;
                    font-size: 14px;
                    font-weight: 500;
                    margin-top: 5px;
                    display: grid;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                }

                img {
                    width: 100%;
                    height: 250px;
                    border-radius: 3px;

                }

            }


        }

        .rightcol {

            .firstcol {
                margin-bottom: 30px;

                .mapdata {
                    display: flex;
                    box-shadow: 0px 0px 14px 3px #8080805e;
                    margin-bottom: 30px;
                    display: flex;
                    align-items: center;
                    border-radius: 3px;
                    transition: all .5s ease;

                    &:hover {
                        transform: scale(1.1);
                    }

                    img {
                        width: 150px;
                        height: 100px;
                        // border-radius: 10px;
                        border-top-left-radius: 3px;
                        border-bottom-left-radius: 3px;
                        margin-right: 10px;
                        // border-right: 2px solid var(--color-pri);
                        // box-shadow: 5px 0px var(--color-pri);
                    }

                    p {
                        padding: 0px 10px;
                    }
                }

                h1 {
                    font-size: 30px;
                    color: var(--color-pri);
                    margin-bottom: 20px;
                }
            }

            .seccol {
                // padding: 0px 30px;
                // margin-bottom: 30px;
                margin-top: 70px;
                box-shadow: 0px 0px 10px 1px var(--color-prisec);
                padding: 10px 0px;
                padding-left: 15px;


                h1 {
                    font-size: 30px;
                    color: var(--color-pri);
                }

                ul {
                    margin-left: 40px;
                    margin-top: 10px;

                    li {
                        color: var(--color-sec);
                        font-size: 18px;
                    }
                }
            }

            .thirdcol {
                width: 100%;
                height: 300px;
                background-position: center;
                background-size: cover;
                border-radius: 3px;
                margin-top: 50px;

                .thirdinner {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    border-radius: 3px;
                    background-color: rgba(0, 0, 0, 0.7);
                    justify-content: center;
                    padding: 0px 30px;
                    color: white;

                    h1 {
                        color: var(--color-pri);
                        font-size: 30px;
                        font-weight: 600;
                    }

                    .flexi {
                        display: flex;
                        align-items: center;
                        margin-top: 10px;

                        a {
                            text-decoration: none;
                            color: white;
                            margin-left: 10px;
                        }

                        .icons {
                            color: var(--color-pri);
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    .Bloglist .Bloglistinner {
        width: 90%;
    }

    .Bloglist .Bloglistinner .leftcol {
        grid-gap: 3rem;
    }

}

@media only screen and (max-width: 1000px) {
    .Bloglist .Bloglistinner {
        grid-template-columns: 1fr;
    }

    .rightcol {
        display: grid;
        // grid-template-columns: repeat(2, 1fr);
    }

    .Bloglist .Bloglistinner .rightcol .firstcol h1 {
        margin-bottom: 0px;
    }

    .Bloglist .Bloglistinner .rightcol .firstcol {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;

        h1 {
            grid-column: span 2;
        }

    }

    .Bloglist .Bloglistinner .rightcol .seccol {
        display: none;
    }

    .Bloglist .Bloglistinner .rightcol .thirdcol {
        margin-top: 0px;
    }
}

@media only screen and (max-width: 775px) {
    .Bloglist .Bloglistinner .rightcol .firstcol {
        display: block;
    }

    .Bloglist .Bloglistinner .rightcol .firstcol h1 {
        margin-bottom: 30px;
    }

    .Bloglist .Bloglistinner .leftcol {
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 475px) {
    .Bloglist .heading {
        margin: 30px 0px 40px 0px;
    }
}