.blogsec {
    width: 100%;
    height: auto;

    .blogsecinner {
        width: 80%;
        height: 100%;
        margin: 0px 10%;

        .blogh {
            width: 100%;
            text-align: center;
            color: var(--color-pri);
            font-size: 40px;
            margin-bottom: 60px;
        }

        .bloginner {
            display: grid;
            width: 100%;
            height: 100%;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 2rem;

            .blogbox {
                width: 100%;
                padding-bottom: 25px;
                transition: all .5s ease;

                &:hover {
                    box-shadow: 0px 2px 0px var(--color-pri);
                    transform: scale(1.05);
                }

                img {
                    width: 100%;
                    border-radius: 3px;
                }

                h1 {
                    color: var(--color-pri);
                    font-size: 24px;
                }

                p {
                    font-size: var(--font-size);

                    // color: var(--color-sec);
                    .span {
                        color: var(--color-pri);

                        a {
                            color: var(--color-pri);
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1098px) {
    .blogsec .blogsecinner .bloginner .blogbox h1 {
        line-height: 1.2em;
        margin: 10px 0px;
        font-size: 20px;

    }
}


@media only screen and (max-width: 800px) {
    .blogsec .blogsecinner .bloginner {
        grid-template-columns: 1fr;
    }

    .blogsec .blogsecinner .blogh {
        margin-bottom: 20px;
        font-size: 40px;
    }
}

@media only screen and (max-width: 698px) {
    .blogsec .blogsecinner .blogh {
        font-size: 30px;
    }
}


@media only screen and (max-width: 375px) {

    .blogsec .blogsecinner .blogh {
        margin-bottom: 30px;
        margin-top: 30px;
    }
}