.Businesstaxsec {
  width: 100%;

  position: relative;

  a {
    color: var(--color-pri);
    border-bottom: 2px solid var(--color-pri);
    text-decoration: none;
    font-weight: 600;
  }

  .Businesstaxsecinner {
    width: 55%;
    height: 100%;
    margin: 0 auto;

    .fp {
      text-align: justify;
      font-size: var(--font-size);
    }

    .fp2 {
      margin-top: 20px;
      font-size: var(--font-size);
      a {
        color: black;
        text-decoration: none;
        border: none;
        font-weight: 500;
      }
    }

    // background: rebeccapurple;
    .h1 {
      font-size: 30px;
      text-transform: capitalize;
    }

    .line {
      width: 80px;
      height: 3px;
      background: var(--color-pri);
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
      font-size: var(--font-size);
    }

    .twoseccol {
      display: grid;
      grid-template-columns: 1fr 300px;
      position: relative;
      align-items: center;

      p {
        br {
          margin-bottom: 20px;
        }
      }

      img {
        width: 250px;
        margin: 0 auto;
        position: absolute;
        right: 0px;
        bottom: 0px;
      }
    }

    .row {
      width: 100%;
      display: grid;

      .cols:last-child {
        border-bottom: 1px solid var(--color-sec);
      }

      .cols {
        .sec {
          display: grid;
          grid-template-columns: 300px 1fr;
          padding: 40px 0px;
          border-top: 1px solid var(--color-sec);
          align-items: center;

          .fb {
            font-weight: 600;
            color: var(--color-pri);
            font-size: 18px;
            width: 240px;
            display: flex;
            align-items: center;
            p {
              margin: 0px;
              line-height: 1.3em;
            }
            img {
              width: 40px;
              margin-right: 10px;
            }
          }
        }
      }
    }
    .dflex {
      display: inline-block;
      align-items: center;
      margin-top: 20px;
      p {
        display: inline;
        a {
          border: none;
          color: black;
          font-weight: 100;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .Businesstaxsec .Businesstaxsecinner {
    width: 90%;
  }

  .Businesstaxsec .Businesstaxsecinner .row .cols .sec {
    grid-template-columns: 240px 1fr;
  }
}

@media only screen and (max-width: 588px) {
  .Businesstaxsec .Businesstaxsecinner .row .cols .sec {
    grid-template-columns: 1fr;
  }

  .Businesstaxsec .Businesstaxsecinner .row .cols .sec .fb {
    width: 100%;
  }
}

// @media only screen and (max-width: 1448px) {
//     .Businesstaxsec .Businesstaxsecinner .twoseccol img {
//         display: none;
//     }

//     .Businesstaxsec .Businesstaxsecinner .twoseccol {
//         grid-template-columns: 1fr !important;
//     }
// }

// @media only screen and (max-width: 650px) {
//     .Businesstaxsec .Businesstaxsecinner {
//         width: 90% !important;
//     }
// }

// @media only screen and (max-width: 428px) {
//     .Businesstaxsec .Businesstaxsecinner .row {
//         grid-template-columns: 1fr !important;
//     }
// }
