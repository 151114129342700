.about {
    .aboutinner {
        margin: 100px 0px;
    }

    .Certification {
        margin-top: 100px;
    }

    .blogcta {
        margin-top: 100px;
    }
    .subbanner .subbannerside .subbannerh1{
        // font-size: 35px !important;
    }
}


@media only screen and (max-width: 600px) {
    .about {
        .aboutinner {
            margin: 50px 0px;
        }
        .Certification {
            margin-top: 50px ;
        }
        .blogcta {
            margin-top: 50px;
        }
    }
}