.banner {
  position: relative;
  width: 100%;
  height: 500px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  color: white;
  .bannerside {
    background: rgba(1, 1, 1, 0.5);
    padding-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    grid-column: span 2;
    .bannerh3 {
      color: var(--color-pri);
      font-size: 28px;
      font-weight: 500;
      // line-height: 1em;
    }

    .bannerh1 {
      color: white;
      font-size: 36px;
      line-height: 1em;
      font-weight: 800;
    }

    .bannerpara {
      font-size: var(--font-size);
      line-height: 1.2em;
      margin: 10px 0px;
      width: 600px;
    }

    .bannerbtn {
      cursor: pointer;
      background: var(--color-pri);
      color: white;
      width: 160px;
      outline: none;
      border: none;
      padding: 10px 0px;
      border-radius: 2px;
      font-size: 17px;
      font-weight: 500;
    }
  }
}

@media only screen and (max-width: 800px) {
  .banner {
    height: 400px;
    grid-template-columns: 1fr;

    .bannerside {
      padding-left: 0px;
      margin: 0 auto;
      width: 100%;
      padding: 50px;

      .bannerh3 {
        font-size: 30px;
      }

      .bannerh1 {
        font-size: 30px;
      }
    }
  }
}
@media only screen and (max-width: 659px) {
  .banner .bannerside .bannerpara {
    width: auto;
  }
  .banner .bannerside {
    padding: 0px;
  }
  .banner .bannerside p {
    margin: 0px 20px;
  }
  .banner .bannerside .bannerpara {
    margin: 10px 20px;
  }
  .banner .bannerside a {
    margin: 0px 20px;
  }
}
@media only screen and (max-width: 410px) {
  .banner {
    background-size: cover;
  }
  .banner .bannerside .bannerh3 {
    line-height: 1.1em;
    margin-bottom: 5px;
  }
}
