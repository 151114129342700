.contactus_formcont {
  width: 100%;
  // min-height: 600px;
  max-height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  padding: 30px 0px;

  .contactforminner {
    width: 80%;
    max-height: auto;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1.3fr 1.7fr;
    border-radius: 3px;

    .contactinnerleft {
      .iconwraper {
        display: grid;
        // margin-left: 40px;

        .contactinnerinfo {
          margin-bottom: 40px;
          width: 70%;

          //   text-align: justify;
          .iconsec {
            display: flex;
            // align-items: center;
            font-size: 20px;
            width: 100%;

            .data {
              font-size: 16px;
              font-weight: 500;

              a {
                text-decoration: none;
                cursor: pointer;
                color: black;
              }

              .extradata {
                font-size: 20px;
                display: block;
                font-weight: 700;
                cursor: pointer;
              }
            }

            //   background: rebeccapurple;
            .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              font-size: 20px;
              color: #fff;
              height: 40px;
              margin-right: 20px;
              width: 40px;
              min-width: 40px;
              // line-height: 63px;
              background-color: var(--color-pri);
            }
          }
        }
      }

      .contacthedi {
        font-size: 37px;
        font-weight: bolder;
        margin-top: 20px;
        margin-bottom: 40px;

        .contactyou {
          color: var(--color-pri);
        }
      }

      .subh {
        font-size: 18px;
        font-weight: bold;
        display: flex;

        .line {
          width: 60px;
          height: 4px;
          margin-top: 11px;
          background: var(--color-pri);
          margin-right: 15px;
        }
      }
    }

    .contactinnerright {
      // background: var(--color-pri);
      // border: 2px solid var(--color-pri);
      box-shadow: 0 -1px 55px 0 rgba(229, 226, 226, 0.425);
      border-radius: 3px;
      color: white;
      padding: 40px 30px;

      .ali {
        display: grid;
        height: 100%;
        align-items: center;
        grid-gap: 1rem;
      }

      .formfields {
        display: grid;
        grid-gap: 1rem;
        // grid-template-columns: repeat(2, 1fr);
        grid-template-columns: 1fr;
        color: var(--color-pri);
        font-weight: 500;

        .tfs {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 2rem;
        }

        .calander {
          color: var(--color-pri);
          font-size: 16px;
        }

        // .spbt{
        //    display: flex;

        // }

        .formfield {
          display: grid;
          margin-bottom: 0;
          color: var(--color-pri);

          p {
            // color: var(--color-pri);
            color: black;
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 5px;
            margin-top: 5px;
          }

          input,
          select {
            box-sizing: border-box;
            width: 100%;
            padding: 6px 5px;
            border-radius: 3px;
            outline: none;
            box-shadow: none;
            border: none;
            border: 1px solid var(--color-pri);
          }

          .textarea {
            box-sizing: border-box;
          }

          .spbt {
            display: flex;
            justify-content: space-between;
            position: relative;
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 5px;
            margin-top: 5px;

            .show {
              display: block;
            }

            .react-calendar.sohw {
              position: absolute;
              top: 90px;
              right: -12px;
            }

            .hidecal {
              display: none;
            }

            span {
              position: relative;
              color: black;
            }
          }

          .flex {
            display: flex;
            justify-content: space-between;

            .radio {
              display: flex;
              align-items: center;

              label {
                color: black;
              }

              input {
                width: auto;
                margin-right: 5px;
                color: var(--color-pri);
              }
            }
          }
        }
      }

      .textareasec {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;

        p {
          font-size: 1.1em;
          color: black;
          margin-bottom: 5px;
        }

        textarea {
          width: 100%;
          height: 100px;
          border-radius: var(--border-radius);
          border: 1px solid var(--color-pri);
          padding: 5px;
        }
      }
    }

    .contactbtn {
      font-size: 16px;
      padding: 8px 30px;
      background: var(--color-pri);
      color: white;
      font-weight: bold;
      border-radius: 3px;
      border: none;
      outline: none;
    }
  }
}

@media screen and (max-width: 1350px) {
  .contactus_formcont {
    .contactforminner {
      width: 95%;
    }
  }
}

@media screen and (max-width: 1130px) {
  .contactus_formcont {
    margin-bottom: 30px;
    padding: 20px 0px;

    .contactforminner {
      .contactinnerright {
        .formfields {
          grid-template-columns: 1fr;
          grid-gap: 0;
        }
      }

      .contactinnerleft {
        .contacthedi {
          //   font-size: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 930px) {
  .contactus_formcont {
    .contactforminner {
      grid-template-columns: 1fr;
      width: 60%;

      .contactinnerright {
        .formfields {
          grid-template-columns: 1fr;
          grid-gap: 0;
        }
      }

      .contactinnerleft {
        display: grid;
        justify-content: center;

        .iconwraper {
          margin-left: 0px;

          .contactinnerinfo {
            .iconsec {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .contactus_formcont {
    .contactforminner {
      grid-template-columns: 1fr;
      width: 100%;
      margin-bottom: 30px;

      .contactinnerleft {
        padding: 40px;

        .contacthedi {
          font-size: 30px;
        }
      }
    }
  }

  .contactus_formcont .contactforminner .contactinnerright {
    padding: 40px 50px;
  }
}

@media screen and (max-width: 538px) {
  .contactus_formcont .contactforminner .contactinnerright .formfields .tfs {
    grid-template-columns: 1fr;
    grid-gap: 0rem;
  }

  input {
    box-sizing: border-box;
    width: 100%;
  }

  textarea {
    box-sizing: border-box;
    width: 100%;
    min-height: 100px;
    max-height: 100px;

  }

  .contactus_formcont .contactforminner .contactinnerright {
    padding: 40px 20px;
  }
  .contactus_formcont .contactforminner .contactinnerleft .iconwraper .contactinnerinfo{
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .contactus_formcont {
    margin-bottom: 0px;
    padding: 0px;
  }
}
